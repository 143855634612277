import moment from 'moment'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["durationType",
                    "durationYears", "yearVisitsCount", "visitsCount",
                    "totalAmount",
                    "weeklyAmount", "biweeklyAmount",
                    "monthlyAmount", "sixMonthsAmount",
                    "quartelyAmount", "annualAmount",
                    "oneTimeAmount", "visitAmount",
                    "yearVisitsContainer", "totalContainer", "oneTimeContainer"]

  get durationType() { return this.durationTypeTargets.find(o => o.checked).value }

  setDurationType() {
    switch(this.durationType) {
    case 'infinite':
      this.totalContainerTarget.classList.add('d-none') // hide
      this.oneTimeContainerTarget.classList.add('d-none') // hide
      this.yearVisitsContainerTarget.classList.remove('d-none') // show
      break
    case 'years':
      this.totalContainerTarget.classList.remove('d-none') // show
      this.oneTimeContainerTarget.classList.remove('d-none') // show
      this.yearVisitsContainerTarget.classList.remove('d-none') // show
      break
    case 'visits':
      this.totalContainerTarget.classList.remove('d-none') // show
      this.oneTimeContainerTarget.classList.remove('d-none') // show
      this.yearVisitsContainerTarget.classList.add('d-none') // hide
      break
    }
  }

  updateYears() {
    let years = parseInt(this.durationYearsTarget.value)
    if( !isNaN(years) && years > 1 ) {
      $("label[for='subscription_plan_total']").html(`Total price for ${years} years`)
    } else {
      $("label[for='subscription_plan_total']").html("Total price")
    }

    if( isFinite(years) ) {
      this.recalculate()
    }
  }

  updateVisits() {
    $("label[for='subscription_plan_total']").html("Total price")

    let visitsCount = parseInt(this.visitsCountTarget.value)
    let visitAmount = parseFloat(this.visitAmountTarget.value)
    if( isFinite(visitsCount) && isFinite(visitAmount) ) {
      this.recalculate()
    }
  }

  updateAmounts(event) {
    this.__updateAmounts(event.target)
  }

  recalculate() {
    // prefer to update all amounts based on total
    if( this.durationType != 'visits' || parseFloat(this.totalAmountTarget.value) > 0 ) {
      this.__updateAmounts(this.totalAmountTarget)
    } else {
      this.__updateAmounts(this.visitAmountTarget)
    }
  }

  __updateAmounts(changedInput) {
    let totalAmount = 0
    let weeklyAmount = 0
    let biweeklyAmount = 0
    let monthlyAmount = 0
    let sixMonthsAmount = 0
    let quartelyAmount = 0
    let annualAmount = 0
    let oneTimeAmount = 0

    let visitAmount = 0
    // do not reset it, because it depends only on visit count and shouldn't be recalculated on dates changes
    if( this.durationType != 'visits' ) {
      visitAmount = parseFloat(this.visitAmountTarget.value)
    }


    let durationYears = parseInt(this.durationYearsTarget.value)
    if( isNaN(durationYears) ) durationYears = 1
    let weeksCount = moment().isoWeeksInYear()
    let biweeksCount = weeksCount / 2
    let yearVisitsCount = parseInt(this.yearVisitsCountTarget.value)
    let visitsCount = parseInt(this.visitsCountTarget.value)

    switch (changedInput.id) {
      case 'subscription_plan_total':
        totalAmount = parseFloat(changedInput.value)
        if( isNaN(totalAmount) ) return;
        annualAmount = totalAmount / durationYears
        break
      case 'subscription_plan_weekly_amount':
        weeklyAmount = parseFloat(changedInput.value)
        annualAmount = weeklyAmount * weeksCount
        break
      case 'subscription_plan_biweekly_amount':
        biweeklyAmount = parseFloat(changedInput.value)
        annualAmount = biweeklyAmount * biweeksCount
      case 'subscription_plan_monthly_amount':
        monthlyAmount = parseFloat(changedInput.value)
        annualAmount = monthlyAmount * 12
        break
      case 'subscription_plan_six_months_amount':
        sixMonthsAmount = parseFloat(changedInput.value)
        annualAmount = sixMonthsAmount * 2
        break
      case 'subscription_plan_quartely_amount':
        quartelyAmount = parseFloat(changedInput.value)
        annualAmount = quartelyAmount * 4
        break
      case 'subscription_plan_annual_amount':
        annualAmount = parseFloat(changedInput.value)
        break
      case 'subscription_plan_one_time_amount':
        oneTimeAmount = parseFloat(changedInput.value)
        annualAmount = oneTimeAmount / durationYears
        break
      case 'subscription_plan_visit_amount':
        visitAmount = parseFloat(changedInput.value)
        break
    }

    if( this.durationType == 'visits' && isFinite(visitsCount) ) { // visitsCount is set, so total and one-time can be set
      if( visitAmount > 0 ) { // do not reset totalAmount if visitAmount not set, visitAmount will be calculated based on total value below
        totalAmount = visitAmount * visitsCount
      }
      oneTimeAmount = totalAmount
    } else if( isFinite(yearVisitsCount) && visitAmount > 0 && annualAmount == 0) {
      annualAmount = visitAmount * yearVisitsCount
    } else {
      totalAmount = annualAmount * durationYears // durationYears is always set, 1 as default
      oneTimeAmount = totalAmount
    }

    weeklyAmount = annualAmount / weeksCount
    biweeklyAmount = annualAmount / biweeksCount
    monthlyAmount = annualAmount / 12
    sixMonthsAmount = annualAmount / 2
    quartelyAmount = annualAmount / 4

    if(isFinite(visitsCount)) visitAmount = totalAmount / visitsCount
    if(isFinite(yearVisitsCount)) visitAmount = annualAmount / yearVisitsCount

    this.totalAmountTarget.value = totalAmount.toFixed(2)
    this.weeklyAmountTarget.value = weeklyAmount.toFixed(2)
    this.biweeklyAmountTarget.value = biweeklyAmount.toFixed(2)
    this.monthlyAmountTarget.value = monthlyAmount.toFixed(2)
    this.sixMonthsAmountTarget.value = sixMonthsAmount.toFixed(2)
    this.quartelyAmountTarget.value = quartelyAmount.toFixed(2)
    this.annualAmountTarget.value = annualAmount.toFixed(2)
    this.oneTimeAmountTarget.value = oneTimeAmount.toFixed(2)
    this.visitAmountTarget.value = visitAmount.toFixed(2)
  }
}
